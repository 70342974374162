//Default Colors
$white: #ffffff;
$black: #000000;

$midnight-green: hsl(186, 98%, 17%);
$light-coral: hsl(0, 87%, 73%);
$rapture-blue: hsl(179, 42%, 63%);
$police-blue: hsl(187, 41%, 29%);
$deep-jungle-green: hsl(186, 100%, 14%);
$sacramento-state-green: hsl(186, 96%, 10%);
$dark-green: hsl(186, 100%, 8%);