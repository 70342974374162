@import url("https://fonts.googleapis.com/css2?family=Livvic:wght@400;700&display=swap");
*,
::after,
::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  background-color: hsl(186, 100%, 8%);
  overflow-x: hidden;
}

h2 {
  background-color: hsl(186, 98%, 17%);
  color: #ffffff;
  font: 100% "Livvic", sans-serif;
}

li,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

input {
  outline: none;
}

input:focus {
  border: none;
}

textarea:focus {
  outline: none;
  border: none;
}

.errorMsg {
  color: #f67e7e;
  font-size: 12px;
  font-family: "Livvic", sans-serif;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
}

.container {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
}

header {
  background-color: hsl(186, 98%, 17%);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 25px 40px 25px;
}
.header__logo {
  display: flex;
  align-items: center;
  gap: 30px;
}
.header__logo ul {
  display: flex;
  gap: 30px;
}
.header__logo ul li a {
  font-family: "Livvic", sans-serif;
  color: #ffffff;
  font-weight: 600;
}
.header__logo ul li a:hover {
  color: hsl(0, 87%, 73%);
}
.header__logo img {
  width: 8rem;
}
.header__menu nav img {
  width: 20px;
  height: 17px;
}
.header__menu .bm-burger-button {
  z-index: 1000;
  position: absolute;
  width: 25px;
  height: 20px;
  right: 36px;
  top: 36px;
}
.header__menu .bm-menu-wrap {
  z-index: 9999;
}
.header__menu .bm-item li {
  margin-bottom: 20px;
}
.header__menu .bm-item li a {
  border: none !important;
  font-weight: 600 !important;
}
.header__menu .bm-item li a:hover {
  background-color: transparent !important;
  color: hsl(0, 87%, 73%) !important;
}
.header__menu .bm-item li:last-child {
  border: 2px solid #ffffff !important;
  padding: 10px 15px;
  border-radius: 30px;
  text-align: center;
  margin-left: 20px;
}
.header__menu .bm-cross-button {
  margin-top: 20px !important;
  margin-right: 30px !important;
}
.header__menu .bm-cross {
  width: 30px;
}
.header__shape {
  position: absolute;
  bottom: 0;
  right: -30px;
}
.header__shape img {
  width: 100px !important;
  height: auto !important;
  padding: 0 !important;
}
.header div:last-child a {
  font-family: "Livvic", sans-serif;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: bold;
}
.header div:last-child a:hover {
  background-color: #ffffff;
  color: #000000;
}

.director-section {
  background-color: hsl(186, 100%, 14%);
  padding-top: 140px;
  padding-bottom: 153px;
  position: relative;
}
.director-section__shape {
  position: absolute;
  right: 0;
  padding-top: 50px;
}
.director-section__shape--left {
  position: absolute;
  left: 0;
  top: 0;
}
.director-section__shape--left img {
  width: 100px;
}
.director-section h2 {
  background: none;
  text-align: center;
  font-size: 2rem;
  font-family: "Livvic", sans-serif;
  font-weight: bold;
  padding-bottom: 50px;
  z-index: 999;
}
.director-section ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  position: relative;
}
.director-section ul li {
  background-color: hsl(186, 96%, 10%);
  display: grid;
  justify-content: center;
  margin-inline: 20px;
}
.director-section ul li h3 {
  font-family: "Livvic", sans-serif;
  color: hsl(179, 42%, 63%);
  font-size: 1rem;
  text-align: center;
}
.director-section ul li p {
  font-style: italic;
  font-family: "Livvic", sans-serif;
  color: #fff;
}
.director-section ul li p:last-child {
  font-style: normal;
}
.director-section ul li > img {
  display: inline-block;
  width: 96px;
  height: auto;
  border-radius: 50%;
  margin: 0 auto;
  border: 2px solid #C4FFFE;
  margin-top: 30px;
  margin-bottom: 20px;
}
.director-section ul li div a {
  position: relative;
  background-color: hsl(0, 87%, 73%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  margin: 0 auto;
  top: 25px;
}
.director-section ul li div a:hover {
  background-color: hsl(179, 42%, 63%);
}
.director-section ul li div img {
  width: 1rem;
  height: 1rem;
}
.director-section ul li span {
  padding-top: 20px;
}
.director-section ul li span i {
  color: #ffffff;
  font-size: 25px;
}
.director-section ul li span i:first-child {
  padding-right: 10px;
}
.director-section ul li:last-child {
  background-color: hsl(186, 100%, 8%);
  padding-inline: 50px;
  text-align: center;
}
.director-section ul li:last-child p {
  color: #ffffff;
  font-family: "Livvic", sans-serif;
}
.director-section ul li:last-child h3 {
  padding-bottom: 10px;
  padding-top: 30px;
}
.director-section ul li:last-child div img {
  transform: rotate(45deg);
}

.clients-section {
  position: relative;
  background-color: hsl(186, 96%, 10%);
}
.clients-section div {
  max-width: 1280px;
  margin: 0 auto;
  padding-block: 110px;
}
.clients-section div h2 {
  background-color: hsl(186, 96%, 10%);
  text-align: center;
  padding-bottom: 50px;
  font-weight: bold;
  font-size: 2rem;
}
.clients-section div ul {
  display: flex;
  justify-content: space-between;
}
.clients-section div ul li img {
  width: 140px;
}
.clients-section__shape {
  position: absolute;
  top: -110px;
}
.clients-section__shape--hide-desktop {
  display: none;
}
.clients-section__shape--show-mobile {
  display: none;
}

.about-header {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 40px 25px 150px 25px;
}
.about-header div p,
.about-header div h1 {
  font-family: "Livvic", sans-serif;
  color: #ffffff;
}
.about-header div h1 {
  font-size: 3rem;
}
.about-header div p {
  line-height: 1.7;
  padding-right: 30px;
}
.about-header__shape {
  position: absolute;
  right: 0;
  margin-top: -50px;
}
.about-header__shape img {
  width: 100px;
}
.about-header__shape--line {
  width: 60px;
  height: 5px;
  background-color: hsl(0, 87%, 73%);
  margin-bottom: 50px;
}

.h_content {
  text-align: center;
  font: 100% "Livvic", sans-serif;
  background-color: hsl(186, 98%, 17%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  padding-top: 50px;
  position: relative;
}
.h_content__shape {
  position: absolute;
  left: -100px;
}
.h_content__shape img {
  width: 200px;
  height: auto;
}
.h_content__line {
  position: relative;
  height: 3px;
  width: 28px;
  background-color: hsl(179, 42%, 63%);
  margin-bottom: 50px;
  margin-left: 15px;
}
.h_content h1 {
  font-size: 3.7rem;
  font-weight: bold;
  color: #ffffff;
  padding: 0 40px;
  line-height: 60px;
  padding-top: 20px;
  text-align: left;
}
.h_content span {
  color: hsl(0, 87%, 73%);
}
.h_content p {
  color: #ffffff;
  font-size: 1rem;
  line-height: 30px;
  padding: 0 15px;
  margin-top: 20px;
  font-weight: normal;
}
.h_content img {
  text-align: center;
  max-width: 100%;
  padding: 0 10px;
  margin-top: 80px;
  position: relative;
  margin-bottom: -4px;
}
.h_content div p {
  text-align: left;
  margin-bottom: 40px;
}

.main {
  background-color: hsl(186, 96%, 10%);
  padding-bottom: 30px;
}
.main__desc {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.main__line {
  position: absolute;
  height: 3px;
  width: 32px;
  margin-top: 30px;
  background-color: hsl(0, 87%, 73%);
}
.main__title {
  grid-column: span 4/span 4;
  padding-top: 20px;
  padding-right: 28px;
  margin: 30px 0;
  font-size: 1.75rem;
  font-weight: bold;
}
.main h2 {
  background-color: inherit;
  font-weight: bold;
}
.main__pattern {
  position: absolute;
  right: 0;
}
.main__pattern p,
.main__pattern h3 {
  z-index: 1;
}
.main__pattern img {
  position: relative;
}
.main__featured h3 {
  color: hsl(0, 87%, 73%);
  text-align: center;
  padding: 5px 0;
  font-family: "Livvic", sans-serif;
}
.main__card {
  margin-bottom: 48px;
}
.main__icon {
  display: flex;
  justify-content: center;
}
.main p {
  color: #ffffff;
  font-family: "Livvic", sans-serif;
  line-height: 1.5;
  text-align: center;
  padding: 0 10px;
  font-size: 0.9375rem;
}

.testimonial {
  background: hsl(186, 100%, 14%);
  position: relative;
  z-index: 1;
}
.testimonial__shape {
  width: 147px;
  height: 100px;
}
.testimonial__quote {
  display: block;
  position: relative;
  top: 20px;
  z-index: 1;
  margin: 30px auto 0 auto;
}
.testimonial h2 {
  background: inherit;
  font-size: 1.75rem;
  text-align: center;
  font-weight: bold;
  padding: 30px 20px;
}
.testimonial blockquote {
  color: hsl(179, 42%, 63%);
}
.testimonial p,
.testimonial h4 {
  text-align: center;
  color: #ffffff;
  font-family: "Livvic", sans-serif;
}
.testimonial h4 {
  color: hsl(179, 42%, 63%);
}
.testimonial p {
  position: relative;
  padding: 0 20px;
  z-index: 9999;
}
.testimonial__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
  margin: 0 50px;
}
.testimonial__cards h4 {
  padding: 20px 0;
}
.testimonial__avatar {
  display: block;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 2px solid #c4fffe;
  margin: 5px auto;
}
.testimonial__pattern {
  display: flex;
  justify-content: flex-end;
  padding-top: 60px;
}

.getstarted {
  position: relative;
  background-color: hsl(0, 87%, 73%);
  padding-block: 90px;
  z-index: 1;
}
.getstarted__pattern {
  position: absolute;
  bottom: -4.5px;
}
.getstarted__description {
  font-family: "Livvic", sans-serif;
}
.getstarted__description div h2 {
  font-size: 32px;
  background-color: inherit;
  padding: 0 20px;
  margin-bottom: 30px;
  font-weight: bold;
  color: hsl(186, 96%, 10%);
}
.getstarted__description div a {
  border: 2px solid hsl(186, 96%, 10%);
  padding: 10px 30px;
  border-radius: 30px;
  font-family: "Livvic", sans-serif;
  font-weight: bold;
  margin-bottom: 30px;
  color: hsl(186, 96%, 10%);
}
.getstarted__description div a:hover {
  background-color: hsl(186, 96%, 10%);
  color: #ffffff;
}
.getstarted div div:first-child {
  display: flex;
  justify-content: space-between;
  padding: 0 150px;
}
.getstarted div div:first-child h2 {
  font-size: 2rem;
}

.footer-sec {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  top: 25px;
}

.contact-section {
  padding-bottom: 50px;
  background-color: hsl(186, 98%, 17%);
  position: relative;
}
.contact-section button {
  font-family: "Livvic", sans-serif;
}
.contact-section__shape {
  position: absolute;
  top: 20px;
}
.contact-section__shape img {
  width: 100px;
}
.contact-section__small {
  display: none;
}
.contact-section__right {
  position: absolute;
  right: 0;
  bottom: 0;
}
.contact-section__description {
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 20px;
  position: relative;
  z-index: 9999;
}
.contact-section__description div h1 {
  font-size: 2.75rem;
  font-family: "Livvic", sans-serif;
  color: #ffffff;
}
.contact-section__description div h2 {
  background: none;
  font-weight: bold;
  font-size: 1.5rem;
  color: hsl(0, 87%, 73%);
}
.contact-section__description div h1,
.contact-section__description div h2 {
  padding-bottom: 20px;
}
.contact-section__description div:first-child ul li {
  display: flex;
  align-items: center;
  gap: 20px;
}
.contact-section__description div:first-child ul li p {
  color: #ffffff;
  font-family: "Livvic", sans-serif;
  font-weight: bold;
}
.contact-section__description div:last-child form {
  display: block;
}
.contact-section__description div:last-child form textarea {
  width: 100%;
  background: none;
  color: #ffffff;
  padding-left: 10px;
  font-family: "Livvic", sans-serif;
  height: 90px;
  font-size: 0.875rem;
  border-bottom: 2px solid hsl(179, 42%, 63%);
}
.contact-section__description div:last-child form textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.2470588235);
}
.contact-section__description div:last-child form textarea::placeholder {
  color: rgba(255, 255, 255, 0.2470588235);
}
.contact-section__description div:last-child form div input {
  background: none;
  border-bottom: 2px solid hsl(179, 42%, 63%);
  width: 100%;
  color: #ffffff;
  padding-left: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
  font-family: "Livvic", sans-serif;
  font-size: 0.875rem;
  max-width: 720px;
}
.contact-section__description div:last-child form div input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.2470588235);
}
.contact-section__description div:last-child form div input::placeholder {
  color: rgba(255, 255, 255, 0.2470588235);
}
.contact-section__description div:last-child form div button {
  font-family: "Livvic", sans-serif;
  padding: 10px 30px;
  border-radius: 30px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: bold;
}

.footer {
  position: relative;
  background: hsl(186, 100%, 8%);
  text-align: center;
  top: -22px;
  padding: 50px;
  color: #ffffff;
  font-family: "Livvic", sans-serif;
}
.footer img {
  width: 96px;
}
.footer__nav {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 20px 0;
}
.footer__nav a {
  color: inherit;
  text-align: center;
  font-weight: bold;
}
.footer__nav a:hover {
  color: hsl(0, 87%, 73%);
}
.footer p {
  text-align: center;
}
.footer__info {
  line-height: 1.5rem;
}
.footer__links {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-top: 40px;
  padding-bottom: 10px;
}
.footer__links img {
  width: 24px;
  height: 24px;
}

.hide-for-desktop {
  display: none;
}

@media (min-width: 1440px) {
  html {
    font-size: 18px;
  }
  .main {
    position: relative;
  }
  .main__container {
    display: flex;
    max-width: 1110px;
    margin: 0 auto;
    padding-top: 90px;
  }
  .main__pattern {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .main__title h2 {
    font-size: 2.25rem;
  }
  .main__icon img {
    width: 72px;
    height: 72px;
  }
  .main__card {
    display: flex;
    margin-bottom: 30px;
  }
  .main__card div {
    padding-right: 20px;
  }
  .main__card div p,
  .main__card div h3 {
    text-align: left;
  }
  .main__card div h3 {
    padding-left: 10px;
    padding-bottom: 20px;
  }
  .testimonial__cards {
    display: flex;
    gap: 20px;
  }
  .director-section ul li:first-child, .director-section ul li:nth-child(2), .director-section ul li:nth-child(3) {
    margin-bottom: 50px;
  }
  .getstarted h2 {
    margin-bottom: 0;
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .footer__logo img {
    width: 160px;
    height: 40px;
  }
  .footer__links {
    justify-content: flex-end;
    position: relative;
    bottom: 45px;
  }
  .footer__info {
    text-align: left;
    color: rgba(255, 255, 255, 0.5019607843);
  }
  .footer__copyright {
    color: rgba(255, 255, 255, 0.5019607843);
  }
}
@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .header {
    display: flex;
  }
  .getstarted__description div {
    display: flex;
    justify-content: space-between;
  }
  .getstarted div div:first-child {
    padding: 0 30px;
  }
  .contact-section__description {
    display: block;
  }
  .contact-section__description div h1,
  .contact-section__description div h2 {
    text-align: center;
  }
  .contact-section__description div ul {
    display: grid;
    justify-content: center;
    margin-bottom: 40px;
  }
  .contact-section__description div:last-child form {
    width: 65%;
    margin: 0 auto;
  }
  .about-header {
    grid-template-columns: 1fr;
  }
  .about-header__shape--line {
    display: none;
  }
  .about-header div {
    text-align: center;
  }
  .about-header div h1 {
    padding-bottom: 15px;
  }
  .about-header div p {
    padding-inline: 100px;
  }
  .director-section ul {
    grid-template-columns: 1fr 1fr;
    margin-inline: 60px;
  }
  .director-section ul li {
    margin-inline: 0;
  }
  .clients-section div ul {
    margin-inline: 40px;
  }
  .clients-section div ul li img {
    width: 90px;
  }
  .clients-section__shape {
    display: none;
  }
  .clients-section__shape--hide-desktop {
    display: block;
    position: absolute;
    top: -110px;
  }
  .clients-section__shape--hide-desktop img {
    width: 200px;
  }
  .hide-for-desktop {
    display: none;
  }
  .h_content h1 {
    font-size: 56px;
    text-align: center !important;
  }
  .h_content__shape {
    display: none;
  }
  .h_content__line {
    display: none;
  }
  .h_content p {
    text-align: center !important;
    line-height: 1.7;
  }
  .main > div {
    padding: 0 40px;
  }
  .main__card {
    display: flex;
    gap: 20px;
  }
  .main p,
  .main h3 {
    text-align: left;
  }
  .testimonial h2 {
    padding: 0 50px;
  }
  .testimonial__cards {
    display: block;
  }
}
@media (max-width: 575px) {
  .h_content {
    padding: 0;
    margin: 0;
  }
  .h_content p {
    margin: 0;
  }
  .h_content h1 {
    font-size: 40px;
    margin: 0;
  }
  .main__card {
    display: block;
  }
  .main p,
  .main h3 {
    text-align: center;
  }
  .hide-for-mobile {
    display: none;
  }
  .hide-for-desktop {
    display: flex;
  }
  .testimonial p {
    padding: 0;
  }
  .about-header div p {
    padding-inline: 10px;
  }
  .director-section .container {
    display: grid;
    justify-content: center;
  }
  .director-section .container ul {
    grid-template-columns: 1fr;
    margin-inline: 30px;
  }
  .clients-section__shape--hide-desktop {
    display: none;
  }
  .clients-section__shape--show-mobile {
    display: block;
    position: absolute;
    top: -110px;
  }
  .clients-section__shape--show-mobile img {
    width: 100px;
  }
  .clients-section div {
    padding-bottom: 30px;
  }
  .clients-section div:last-child ul {
    display: block;
    text-align: center;
  }
  .clients-section div:last-child ul li {
    padding-bottom: 50px;
  }
  .clients-section div:last-child ul li img {
    width: 120px;
  }
  .getstarted__pattern {
    z-index: -1;
    bottom: -5px;
  }
  .getstarted div div:first-child {
    display: block;
    text-align: center;
  }
  .contact-section {
    position: relative;
  }
  .contact-section__shape, .contact-section__right {
    display: none;
  }
  .contact-section__small {
    display: block;
    position: absolute;
    right: 0;
    margin-top: -63px;
  }
  .contact-section__description {
    display: block;
    z-index: 999;
    position: relative;
  }
  .contact-section__description div:last-child form {
    width: 100%;
    padding-right: 30px;
  }
}/*# sourceMappingURL=style.css.map */