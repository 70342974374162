@use "variables" as v;
@use "typography" as t;

*,
::after,
::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  background-color: v.$dark-green;
  overflow-x: hidden;
}

h2 {
  background-color: v.$midnight-green;
  color: v.$white;
  font: 100% t.$font-stack;
}
li,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}

input{
  outline: none;
}

input:focus{
  border: none;
}
textarea:focus {
  outline: none; 
  border: none; 
}
.errorMsg{
  color: #f67e7e;
  font-size: 12px;
  font-family: t.$font-stack;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
}


.container {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
}
header {
  background-color: v.$midnight-green;
}

@mixin justify-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  @include justify-center;
  padding: 40px 25px 40px 25px;
  &__logo {
    display: flex;
    align-items: center;
    gap: 30px;
    ul {
      display: flex;
      gap: 30px;
      li {
        a {
          font-family: t.$font-stack;
          color: v.$white;
          font-weight: 600;
          &:hover {
            color: v.$light-coral;
          }
        }
      }
    }
    img {
      width: 8rem;
    }
  }

  &__menu {
    nav {
      img {
        width: 20px;
        height: 17px;
      }
    }

    .bm-burger-button {
      z-index: 1000;
      position: absolute;
      width: 25px;
      height: 20px;
      right: 36px;
      top: 36px;
    }
    .bm-menu-wrap {
      z-index: 9999;
    }
    .bm-item {
      li {
        margin-bottom: 20px;
        a {
          border: none !important;
          font-weight: 600 !important;
          &:hover {
            background-color: transparent !important;
            color: v.$light-coral !important;
          }
        }
        &:last-child {
          border: 2px solid v.$white !important;
          padding: 10px 15px;
          border-radius: 30px;
          text-align: center;
          margin-left: 20px;
        }
      }
    }
    .bm-cross-button {
      margin-top: 20px !important;
      margin-right: 30px !important;
    }
    .bm-cross {
      width: 30px;
    }
  }
  &__shape {
    position: absolute;
    bottom: 0;
    right: -30px;
    img {
      width: 100px !important;
      height: auto !important;
      padding: 0 !important;
    }
  }
  div {
    &:last-child {
      a {
        font-family: t.$font-stack;
        color: v.$white;
        border: 1px solid v.$white;
        padding: 10px 20px;
        border-radius: 30px;
        font-weight: bold;
        &:hover {
          background-color: v.$white;
          color: v.$black;
        }
      }
    }
  }
}
.director-section {
  background-color: v.$deep-jungle-green;
  padding-top: 140px;
  padding-bottom: 153px;
  position: relative;

  &__shape {
    position: absolute;
    right: 0;
    padding-top: 50px;
    &--left {
      position: absolute;
      left: 0;
      top: 0;
      img {
        width: 100px;
      }
    }
  }

  h2 {
    background: none;
    text-align: center;
    font-size: 2rem;
    font-family: t.$font-stack;
    font-weight: bold;
    padding-bottom: 50px;
    z-index: 999;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
    position: relative;
    li {
      background-color: v.$sacramento-state-green;
      display: grid;
      justify-content: center;
      margin-inline: 20px;
      h3 {
        font-family: t.$font-stack;
        color: v.$rapture-blue;
        font-size: 1rem;
                text-align: center;
      }
      p{
        font-style: italic;
        font-family: t.$font-stack;
        color: #fff;

        &:last-child{
          font-style: normal;
        }
      }
      & > img {
        display: inline-block;
        width: 96px;
        height: auto;
        border-radius: 50%;
        margin: 0 auto;
        border: 2px solid #C4FFFE;
        margin-top: 30px;
        margin-bottom: 20px;
      }
      div {
        
        a{
        position: relative;
        background-color: v.$light-coral;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        width: 56px;
        margin: 0 auto;
        top: 25px;
        &:hover {
          background-color: v.$rapture-blue;
        }
        }
        
        img {
          width: 1rem;
          height: 1rem;
        }
      }
      span {
        padding-top: 20px;
        i {
          color: v.$white;
          font-size: 25px;
          &:first-child {
            padding-right: 10px;
          }
        }
      }
      &:last-child {
        background-color: v.$dark-green;
        padding-inline: 50px;
        text-align: center;

        p {
          color: v.$white;
          font-family: t.$font-stack;
        }
        h3 {
          padding-bottom: 10px;
          padding-top: 30px;
        }
        div {
          img {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
.clients-section {
  position: relative;
  background-color: v.$sacramento-state-green;
  div {
    max-width: 1280px;
    margin: 0 auto;
    padding-block: 110px;

    h2 {
      background-color: v.$sacramento-state-green;
      text-align: center;
      padding-bottom: 50px;
      font-weight: bold;
      font-size: 2rem;
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        img {
          width: 140px;
        }
      }
    }
  }
  &__shape {
    position: absolute;
    top: -110px;
    &--hide-desktop {
      display: none;
    }
    &--show-mobile {
      display: none;
    }
  }
}
.about-header {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 40px 25px 150px 25px;
  div {
    p,
    h1 {
      font-family: t.$font-stack;
      color: v.$white;
    }
    h1 {
      font-size: 3rem;
    }
    p {
      line-height: 1.7;
      padding-right: 30px;
    }
  }
  &__shape {
    position: absolute;
    right: 0;
    margin-top: -50px;
    img {
      width: 100px;
    }
    &--line {
      width: 60px;
      height: 5px;
      background-color: v.$light-coral;
      margin-bottom: 50px;
    }
  }
}

.h_content {
  text-align: center;
  font: 100% t.$font-stack;
  background-color: v.$midnight-green;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  padding-top: 50px;
  position: relative;
  &__shape {
    position: absolute;
    left: -100px;
    img {
      width: 200px;
      height: auto;
    }
  }
  &__line {
    position: relative;
    height: 3px;
    width: 28px;
    background-color: v.$rapture-blue;
    margin-bottom: 50px;
    margin-left: 15px;
  }
  h1 {
    font-size: 3.7rem;
    font-weight: bold;
    color: v.$white;
    padding: 0 40px;
    line-height: 60px;
    padding-top: 20px;
    text-align: left;
  }
  span {
    color: v.$light-coral;
  }
  p {
    color: v.$white;
    font-size: 1rem;
    line-height: 30px;
    padding: 0 15px;
    margin-top: 20px;
    font-weight: normal;
  }
  img {
    text-align: center;
    max-width: 100%;
    padding: 0 10px;
    margin-top: 80px;
    position: relative;
    margin-bottom: -4px;
  }
  div {
    p {
      text-align: left;
      margin-bottom: 40px;
    }
  }
}

.main {
  background-color: v.$sacramento-state-green;
  padding-bottom: 30px;
 
  &__desc {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  &__line {
    position: absolute;
    height: 3px;
    width: 32px;
    margin-top: 30px;
    background-color: v.$light-coral;
  }
  &__title {
    grid-column: span 4 / span 4;
    padding-top: 20px;
    padding-right: 28px;
    margin: 30px 0;
    font-size: 1.75rem;
    font-weight: bold;
  }
  h2 {
    background-color: inherit;
    font-weight: bold;
  }
  &__pattern {
    position: absolute;
    right: 0;
    p,
    h3 {
      z-index: 1;
    }
    img {
      position: relative;
    }
  }
  &__featured {
    h3 {
      color: v.$light-coral;
      text-align: center;
      padding: 5px 0;
      font-family: t.$font-stack;
    }
  }
  &__card {
    margin-bottom: 48px;
  }
  &__icon {
    display: flex;
    justify-content: center;
  }
  p {
    color: v.$white;
    font-family: t.$font-stack;
    line-height: 1.5;
    text-align: center;
    padding: 0 10px;
    font-size: 0.9375rem;
  }
}

.testimonial {
  background: v.$deep-jungle-green;
  position: relative;
  z-index: 1;
  &__shape {
    width: 147px;
    height: 100px;
  }
  &__quote {
    display: block;
    position: relative;
    top: 20px;
    z-index: 1;
    margin: 30px auto 0 auto;
  }
  h2 {
    background: inherit;
    font-size: 1.75rem;
    text-align: center;
    font-weight: bold;
    padding: 30px 20px;
  }
  blockquote {
    color: v.$rapture-blue;
  }
  p,
  h4 {
    text-align: center;
    color: v.$white;
    font-family: t.$font-stack;
  }
  h4 {
    color: v.$rapture-blue;
  }
  p {
    position: relative;
    padding: 0 20px;
    z-index: 9999;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
    margin: 0 50px;
    h4 {
      padding: 20px 0;
    }
  }
  &__avatar {
    display: block;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    border: 2px solid #c4fffe;
    margin: 5px auto;
  }
  &__pattern {
    display: flex;
    justify-content: flex-end;
    padding-top: 60px;
  }
}

.getstarted {
  position: relative;
  background-color: v.$light-coral;
  padding-block: 90px;
  z-index: 1;
  &__pattern {
    position: absolute;
    bottom: -4.5px;
  }
  &__description {
    font-family: t.$font-stack;
    div {
      h2 {
        font-size: 32px;
        background-color: inherit;
        padding: 0 20px;
        margin-bottom: 30px;
        font-weight: bold;
        color: v.$sacramento-state-green;
      }
      a {
        border: 2px solid v.$sacramento-state-green;
        padding: 10px 30px;
        border-radius: 30px;
        font-family: t.$font-stack;
        font-weight: bold;
        margin-bottom: 30px;
        color: v.$sacramento-state-green;
        &:hover {
          background-color: v.$sacramento-state-green;
          color: v.$white;
        }
      }
    }
  }
  div {
    div {
      &:first-child {
        display: flex;
        justify-content: space-between;
        padding: 0 150px;
        h2 {
          font-size: 2rem;
        }
      }
    }
  }
}
.footer-sec {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  top: 25px;
}
.contact-section {
  padding-bottom: 50px;
  background-color: v.$midnight-green;
  position: relative;
  button{
    font-family: t.$font-stack;
  }
  &__shape {
    position: absolute;
    top: 20px;
    img {
      width: 100px;
    }
  }
  &__small {
    display: none;
  }
  &__right {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &__description {
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 20px;
    position: relative;
    z-index: 9999;
    div {
      h1 {
        font-size: 2.75rem;
        font-family: t.$font-stack;
        color: v.$white;
      }
      h2 {
        background: none;
        font-weight: bold;
        font-size: 1.5rem;
        color: v.$light-coral;
      }
      h1,
      h2 {
        padding-bottom: 20px;
      }
      &:first-child {
        ul {
          li {
            display: flex;
            align-items: center;
            gap: 20px;
            p {
              color: v.$white;
              font-family: t.$font-stack;
              font-weight: bold;
            }
          }
        }
      }
      &:last-child {
        form {
          display: block;

          textarea {
            width: 100%;
            background: none;
            color: v.$white;
            padding-left: 10px;
            font-family: t.$font-stack;
            height: 90px;
            font-size: 0.875rem;
            border-bottom: 2px solid v.$rapture-blue;
            &::placeholder{
              color: #ffffff3f;
            }
          }
          div {
            input {
              background: none;
              border-bottom: 2px solid v.$rapture-blue;
              width: 100%;
              color: v.$white;
              padding-left: 10px;
              padding-bottom: 20px;
              padding-top: 20px;
              margin-bottom: 10px;
              font-family: t.$font-stack;
              font-size: 0.875rem;
              max-width: 720px;
              &::placeholder{
                color: #ffffff3f;
              }
            }
            button {
              font-family: t.$font-stack;
              padding: 10px 30px;
              border-radius: 30px;
              margin-top: 20px;
              cursor: pointer;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
.footer {
  position: relative;
  background: v.$dark-green;
  text-align: center;
  top: -22px;
  padding: 50px;
  color: v.$white;
  font-family: t.$font-stack;
  img {
    width: 96px;
  }
  &__nav {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 20px 0;
    a {
      color: inherit;
      text-align: center;
      font-weight: bold;
      &:hover {
        color: v.$light-coral;
      }
    }
  }
  p {
    text-align: center;
  }
  &__info {
    line-height: 1.5rem;
  }
  &__links {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding-top: 40px;
    padding-bottom: 10px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.hide-for-desktop {
  display: none;
}

@media (min-width: 1440px) {
  html {
    font-size: 18px;
  }

  .main {
    position: relative;
    &__container {
      display: flex;
      max-width: 1110px;
      margin: 0 auto;
      padding-top: 90px;
    }
    &__pattern {
      position: absolute;
      right: 0;

      bottom: 0;
    }
    &__title {
      h2 {
        font-size: 2.25rem;
      }
    }
    &__icon {
      img {
        width: 72px;
        height: 72px;
      }
    }
    &__card {
      display: flex;
      margin-bottom: 30px;
      div {
        padding-right: 20px;
        p,
        h3 {
          text-align: left;
        }
        h3 {
          padding-left: 10px;
          padding-bottom: 20px;
        }
      }
    }
  }
  .testimonial {
    &__cards {
      display: flex;
      gap: 20px;
    }
  }
  .director-section {
    ul {
      li {
        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          margin-bottom: 50px;
        }
      }
    }
  }
  .getstarted {
    h2 {
      margin-bottom: 0;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    &__logo {
      img {
        width: 160px;
        height: 40px;
      }
    }
    &__links {
      justify-content: flex-end;
      position: relative;
      bottom: 45px;
    }
    &__info {
      text-align: left;
      color: #ffffff80;
    }
    &__copyright {
      color: #ffffff80;
    }
  }
}

@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .header {
    display: flex;
  }
  .getstarted {
    &__description {
      div {
        display: flex;
        justify-content: space-between;
      }
    }
    div {
      div {
        &:first-child {
          padding: 0 30px;
        }
      }
    }
  }
  .contact-section {
    &__description {
      display: block;

      div {
        h1,
        h2 {
          text-align: center;
        }
        ul {
          display: grid;
          justify-content: center;
          margin-bottom: 40px;
        }
        &:last-child {
          form {
            width: 65%;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .about-header {
    grid-template-columns: 1fr;
    &__shape {
      &--line {
        display: none;
      }
    }
    div {
      text-align: center;
      h1 {
        padding-bottom: 15px;
      }
      p {
        padding-inline: 100px;
      }
    }
  }
  .director-section {
    ul {
      grid-template-columns: 1fr 1fr;
      margin-inline: 60px;
      li {
        margin-inline: 0;
      }
    }
  }
  .clients-section {
    div {
      ul {
        margin-inline: 40px;
        li {
          img {
            width: 90px;
          }
        }
      }
    }
    &__shape {
      display: none;
      &--hide-desktop {
        display: block;
        position: absolute;
        top: -110px;
        img {
          width: 200px;
        }
      }
    }
  }
  .hide-for-desktop {
    display: none;
  }
  .h_content {
    h1 {
      font-size: 56px;
      text-align: center !important;
    }
    &__shape {
      display: none;
    }
    &__line {
      display: none;
    }
    p {
      text-align: center !important;
      line-height: 1.7 ;
    }
  }
  .main {
    & > div {
      padding: 0 40px;
    }
    &__card {
      display: flex;
      gap: 20px;
    }
    p,
    h3 {
      text-align: left;
    }
  }
  .testimonial {
    h2 {
      padding: 0 50px;
    }
    &__cards {
      display: block;
    }
  }
}

@media (max-width: 575px) {
  .h_content {
    padding: 0;
    margin: 0;
    p {
      margin: 0;
    }
    h1 {
      font-size: 40px;
      margin: 0;
    }
  }
  .main {
    &__card {
      display: block;
    }
    p,
    h3 {
      text-align: center;
    }
  }
  .hide-for-mobile {
    display: none;
  }
  .hide-for-desktop {
    display: flex;
  }

  .testimonial {
    p {
      padding: 0;
    }
  }
  .about-header {
    div {
      p {
        padding-inline: 10px;
      }
    }
  }
  .director-section {
    .container {
      display: grid;

      justify-content: center;
      ul {
        grid-template-columns: 1fr;
        margin-inline: 30px;
      }
    }
  }

  .clients-section {
    &__shape {
      &--hide-desktop {
        display: none;
      }
      &--show-mobile {
        display: block;
        position: absolute;
        top: -110px;
        img {
          width: 100px;
        }
      }
    }
    div {
      padding-bottom: 30px;
      &:last-child {
        ul {
          display: block;
          text-align: center;
          li {
            padding-bottom: 50px;
            img {
              width: 120px;
            }
          }
        }
      }
    }
  }

  .getstarted {
    &__pattern {
      z-index: -1;

      bottom: -5px;
    }
    div {
      div {
        &:first-child {
          display: block;
          text-align: center;
        }
      }
    }
  }

  .contact-section {
    position: relative;
    &__shape,
    &__right {
      display: none;
    }
    &__small {
      display: block;
      position: absolute;
      right: 0;
      margin-top: -63px;
    }

    &__description {
      display: block;
      z-index: 999;
      position: relative;
      div {
        &:last-child {
          form {
            width: 100%;
            padding-right: 30px;
          }
        }
      }
    }
  }
}
